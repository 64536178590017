import { Typography } from "@material-ui/core"
import { GeelyCarModelGenericInfoComponent } from "api"
import Image from "next/image"
import styled from "styled-components"

const StyledCardContainer = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: 10px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: row;
    align-items: flex-start;
    width: 30%;
    height: 150px;
  }
`

const StyledIcon = styled.div`
  width: 100px;
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-left: 20px;
    width: 59px;
  }
  display: flex;
  justify-content: center;
`
const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 200px;
    margin-top: 0;
  }
`

const StyledTitle = styled(Typography).attrs({ component: "h3" })`
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  ${(p) => p.theme.breakpoints.up("md")} {
    text-align: right;
    font-size: 20px;
  }
`
const StyledDescription = styled(Typography)`
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  ${(p) => p.theme.breakpoints.up("md")} {
    text-align: right;
  }
`

const FeatureCard = ({
  feature,
}: {
  feature: GeelyCarModelGenericInfoComponent
}) => {
  return (
    <StyledCardContainer>
      <StyledIcon>
        <Image width="100" height="103" src={feature.image.imageUrl} alt="" />
      </StyledIcon>
      <StyledTextContainer>
        <StyledTitle>{feature.mainTitle}</StyledTitle>
        <StyledDescription>{feature.subTitle}</StyledDescription>
      </StyledTextContainer>
    </StyledCardContainer>
  )
}

export default FeatureCard
