import { Typography } from "@material-ui/core"
import { GeelyCarModelResponse } from "api"
import BottomButtons from "common/BottomButtons"
import { Desktop, useIsDesktop } from "common/MediaQueries"
import React from "react"
import { useInView } from "react-intersection-observer"
import styled from "styled-components"
import FeatureCard from "./FeatureCard"

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`

const StyledFeatureContainer = styled.div<{ $inView: boolean }>`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 100%;
    margin-top: 120px;
    margin-bottom: 90px;

    align-items: center;
    flex-direction: row;
    justify-content: space-evenly;
    transition: width 300ms ease-in-out;
    ${(p) => (!p.$inView ? `width: 70%` : ` width: 100%`)};
  }
`
const StyledTitleContainer = styled(Typography).attrs({
  variant: "h1",
  component: "h2",
})`
  font-size: 26px;
  text-align: center;
  font-weight: normal;
  margin-bottom: 20px;
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 20%;
    font-size: 48px;
  }
`
const StyledDesktopFeatures = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 850px;
    height: 250px;
  }
`
const StyledBottomWrap = styled.div`
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: -30px;
    margin-bottom: -30px;
  }
`
const PeaceOfMind = ({ data }: { data: GeelyCarModelResponse }) => {
  const isDesktop = useIsDesktop()
  const features = data.peaceOfMindComponent?.items
  const { ref, inView } = useInView({
    threshold: 0.3,
  })

  return (
    <StyledMainContainer ref={ref}>
      <StyledFeatureContainer $inView={inView}>
        <StyledTitleContainer>
          {isDesktop ? (
            <>
              שקט
              <br /> נפשי
            </>
          ) : (
            <>שקט נפשי</>
          )}
        </StyledTitleContainer>
        <StyledDesktopFeatures>
          {features.map((feature, index) => {
            return <FeatureCard key={index} feature={feature} />
          })}
        </StyledDesktopFeatures>
      </StyledFeatureContainer>
      <Desktop>
        <StyledBottomWrap>
          <BottomButtons />
        </StyledBottomWrap>
      </Desktop>
    </StyledMainContainer>
  )
}

export default PeaceOfMind
